import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

const NotFound = () => {
  useEffect(() => {
    navigate('/')
  }, [])

  return <></>
}

export default NotFound
